<script setup>

</script>

<template>
  <section class="companyDescription">
    <p>
      Paraleclypse est un studio indépendant de distribution et de production. Nous travaillons
      des oeuvres de fiction et de documentaire pour le cinéma. Notre objectif est de faire
      découvrir un cinéma différent, sous un tout nouveau format, tout en soutenant des projets
      de jeunes réalisateurs.
    </p>
  </section>
</template>

<style scoped lang="scss">
  .companyDescription {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    p {
      font-size: 1.1em;
      text-transform: uppercase;
      text-align: justify;
      color: #aeaeae;
      font-family: Syne, sans-serif;
      width: 25%;
      padding: 0 3rem;
    }
  }

  @media (max-width: 800px) {
    .companyDescription {
      p {
        width: 100%;
        font-size: 14px;
        padding: 0;
      }
    }
  }
</style>
